import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';

import { userList } from '@src/clients/3thix';
import { Error3thix, SuccessUserList } from '@src/types';

import ModalCreateUser from './components/ModalCreateUser';
import { getColumns, PAGE_SIZE } from './constants';

type Props = {
  entityID: string;
};

const UsersTable = ({ entityID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [usersResp, setUsersResp] = useState<SuccessUserList | null>(null);

  const getUsers = useCallback(async () => {
    const { data, status } = await userList(page, pageSize, entityID, null, null);

    if (status === 200) {
      setUsersResp(data as SuccessUserList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, entityID]);

  const toggleCreateModal = () => setOpenCreateModal(!openCreateModal);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const refreshUsers = useCallback(() => {
    getUsers();
  }, [getUsers]);

  const rows = useMemo(() => {
    if (usersResp === null) {
      return [];
    }

    return usersResp.users;
  }, [usersResp]);

  return (
    <div className="text-right">
      <ModalCreateUser
        entityID={entityID}
        isOpen={openCreateModal}
        toggleOpen={toggleCreateModal}
        onRefresh={refreshUsers}
      />

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && usersResp !== null && (
          <Table
            dataSource={rows}
            columns={getColumns(entityID)}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: usersResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UsersTable;
