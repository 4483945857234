import { Relationship } from '@src/types';

export const getColumns = (parentId: string) => [
  { title: 'First Name', dataIndex: 'first_name' },
  { title: 'Last Name', dataIndex: 'last_name' },
  { title: 'Email', dataIndex: 'email' },
  { title: 'Phone', dataIndex: 'phone' },
  { title: 'ID', dataIndex: 'id' },
  {
    title: 'Third Party ID',
    dataIndex: 'relationships',
    render: (relationships: Relationship[]) => {
      return relationships
        .filter((relationship) => relationship.parent_entity_id === parentId)
        .reduce((accumulator: string, currentValue) => {
          if (accumulator === '') {
            return currentValue.third_party_id;
          }
          return `${accumulator} - ${currentValue.third_party_id}`;
        }, '');
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return new Date(created_at).toDateString();
    },
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: (updated_at: string) => {
      return new Date(updated_at).toDateString();
    },
  },
];

export const PAGE_SIZE = 5;
