import { useCallback, useState } from 'react';

import { Button, Input, message, Modal, Select } from 'antd';

import { notificationWebhooksCreate } from '@src/clients/3thix';
import { NotificationEventsWebhook3thix, NotificationEventsWebhookCustomer } from '@src/constants';
import { useEntity } from '@src/contexts/EntityContext';
import { Error3thix } from '@src/types';

type Props = {
  toggleOpen: () => void;
  isOpen: boolean;
  onSuccess: () => void;
};

const ModalCreate = ({ toggleOpen, isOpen, onSuccess }: Props) => {
  const { selectedEntity } = useEntity();
  const [formData, setFormData] = useState({
    entity_id: '',
    notification_event: '',
    destination_url: '',
    auth_type: '',
    auth_token: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await notificationWebhooksCreate(formData);
      if (status === 201) {
        onSuccess();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onSuccess]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-code-pull-request mr-2"></i> Webhook
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Entity ID
            </label>
            <Input
              required
              size="large"
              type="text"
              name="entity_id"
              defaultValue={formData.entity_id}
              placeholder="1234"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Notification Event
            </label>
            <Select<string>
              size="large"
              defaultValue={formData.notification_event}
              className="my-2 w-full"
              onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, notification_event: value }))}
            >
              {selectedEntity?.type === 'ROOT'
                ? NotificationEventsWebhook3thix.map((event) => (
                    <Select.Option key={event} value={event}>
                      {event}
                    </Select.Option>
                  ))
                : NotificationEventsWebhookCustomer.map((event) => (
                    <Select.Option key={event} value={event}>
                      {event}
                    </Select.Option>
                  ))}
            </Select>
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Destination URL
            </label>
            <Input
              required
              size="large"
              type="text"
              name="destination_url"
              defaultValue={formData.destination_url}
              placeholder="http://api.domain.com"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Auth type
            </label>
            <Input
              size="large"
              type="text"
              name="auth_type"
              defaultValue={formData.auth_type}
              placeholder="Bearer"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Auth Token
            </label>
            <Input
              size="large"
              type="text"
              name="auth_token"
              defaultValue={formData.auth_token}
              placeholder="code"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreate;
