import { useCallback, useState } from 'react';

import { Button, Input, message, Modal, Select } from 'antd';

import { notificationEmailConfigsCreate } from '@src/clients/3thix';
import { NotificationEventsEmail3thix } from '@src/constants';
import { Error3thix } from '@src/types';

type Props = {
  toggleOpen: () => void;
  isOpen: boolean;
  onSuccess: () => void;
};

const ModalCreate = ({ toggleOpen, isOpen, onSuccess }: Props) => {
  const [formData, setFormData] = useState({
    entity_id: '',
    notification_event: '',
    destination_email: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await notificationEmailConfigsCreate(formData);
      if (status === 201) {
        onSuccess();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onSuccess]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-envelope mr-2"></i> E-mail Config
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Entity ID
            </label>
            <Input
              required
              size="large"
              type="text"
              name="entity_id"
              defaultValue={formData.entity_id}
              placeholder="1234"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              Notification Event
            </label>
            <Select<string>
              size="large"
              defaultValue={formData.notification_event}
              className="my-2 w-full"
              onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, notification_event: value }))}
            >
              {NotificationEventsEmail3thix.map((event) => (
                <Select.Option key={event} value={event}>
                  {event}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
              E-mail
            </label>
            <Input
              required
              size="large"
              type="text"
              name="destination_email"
              defaultValue={formData.destination_email}
              placeholder="email@domain.com"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>
          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreate;
